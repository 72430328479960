import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import SEO from "../../components/seo"

const Contact = ({ data, location }) => (
  <Layout>

    <SEO
      pagetitle="Contact thanks"
      pagedesc="マイクロビット（microbit）を中心にプログラミング教育教材を提供いたします。"
      pagepath={location.pathname}
      pageimg={data.contact.childImageSharp.original.src}
      pageimgw={data.contact.childImageSharp.original.width}
      pageimgh={data.contact.childImageSharp.original.height}
    />

    <div className="eyecatch">
      <figure>
        <Img
          fluid={data.contact.childImageSharp.fluid}
          alt="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
          loading="eager"
          durationFadeIn={100}
        />
      </figure>
    </div>

    <article className="content">
      <div className="container">

        <aside className="info">
          <div className="subtitle">
            <h1 className="bar"><FontAwesomeIcon icon={faQuestionCircle} />お問い合わせ</h1>
          </div>
        </aside>
        <div className="postbody">
            <h3>
              お問合せありがとうございます。後日、営業担当者よりご連絡いたします。<br/><br/>
              また、現在お問合せが大変混み合っており、ご連絡が遅くなることがございます。<br/>
              大変申し訳ございませんが、ご了承いただきますよう、お願いいたします。<br/>
            </h3>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    contact: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`
export default Contact
